import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { Line, Pie, Bar } from 'react-chartjs-2';
import './AnalyticsPage.css';

const AnalyticsPage = () => {
  const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Positive Feedback',
        data: [65, 59, 80, 81, 56, 55, 40],
        borderColor: '#36a2eb',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
        label: 'Negative Feedback',
        data: [28, 48, 40, 19, 86, 27, 90],
        borderColor: '#ff6384',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };

  const pieData = {
    labels: ['Positive', 'Negative'],
    datasets: [
      {
        data: [300, 50],
        backgroundColor: ['#36a2eb', '#ff6384'],
        hoverBackgroundColor: ['#36a2eb', '#ff6384'],
      },
    ],
  };

  const barData = {
    labels: ['Quality', 'Value', 'Service', 'Design', 'Usability'],
    datasets: [
      {
        label: 'Positive Feedback',
        data: [4, 3, 5, 2, 6],
        backgroundColor: '#36a2eb',
      },
      {
        label: 'Negative Feedback',
        data: [2, 2, 1, 2, 1],
        backgroundColor: '#ff6384',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div className="analytics-page">
      <header className="analytics-header">
        <div className="logo">МойСервис</div>
        <nav className="analytics-nav">
          <div className="nav-item">Обзор</div>
          <div className="nav-item">Открытия</div>
          <div className="nav-item">Лента</div>
          <div className="nav-item">Аномалии</div>
          <div className="nav-item">Ошибки</div>
          <div className="nav-item">Панели</div>
        </nav>
        <div className="search-bar">
          <FaSearch />
          <input type="text" placeholder="Ищите что угодно" />
        </div>
      </header>
      <section className="hero-section">
        <p>Аналитика отзывов о вашем продукте</p>
        <div className="search-container">
          <FaSearch />
          <input type="text" placeholder="Ищите что угодно о вашем продукте" />
          <button className="discover-button">Открыть аналитику</button>
        </div>
      </section>
      <main className="analytics-main">
        <aside className="sidebar">
          <h3>Категории</h3>
          <button className="category-tab">Количество</button>
          <button className="category-tab">Настроение</button>
          <ul className="category-list">
            <li>Все категории</li>
            <li>Общее</li>
            <li>Приложение</li>
            <li>Аккаунт</li>
            <li>Подделка/Мошенничество</li>
            <li>Ценности</li>
            <li>Бренд</li>
            <li>Подписка</li>
            <li>Поддержка</li>
            <li>Другое</li>
          </ul>
        </aside>
        <section className="analytics-content">
          <div className="analytics-row">
            <div className="analytics-block">
              <h3>Анализ отзывов по месяцам</h3>
              <Line data={lineData} />
            </div>
            <div className="analytics-block">
              <h3>Распределение отзывов</h3>
              <Pie data={pieData} />
            </div>
          </div>
          <div className="analytics-row">
            <div className="analytics-block">
              <h3>Рейтинги по аспектам</h3>
              <Bar data={barData} options={options} />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AnalyticsPage;
