import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';

const LoginPage = ({ closeModal }) => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    // Implement your login logic here
    navigate('/main'); // Redirect to MainPage after successful login
    closeModal(); // Close the modal
  };

  const handleRegister = () => {
    navigate('/register'); // Redirect to RegisterPage
    closeModal(); // Close the modal
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-left"></div>
        <div className="login-right">
          <form onSubmit={handleLogin} className="login-form">
            <h2>С возвращением :)</h2>
            <p>Чтобы оставаться на связи с нами, пожалуйста, войдите, используя ваш номер телефона и пароль</p>
            <input
              type="text"
              placeholder="Номер телефона"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="password"
              placeholder="Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="remember-me">
              <a href="#" className="forgot-password">Забыли пароль?</a>
            </div>
            <button type="submit" className="login-button">Войти</button>
            <button type="button" className="register-button" onClick={handleRegister}>Создать аккаунт</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
