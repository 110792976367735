import React, { useState } from 'react';
import Slider from 'react-slick';
import { AiFillDatabase, AiFillBell, AiFillPieChart } from 'react-icons/ai';
import { FaSearch } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './MainPage.css';

const MainPage = () => {
  const [productLink, setProductLink] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const message = `
      Имя: ${formData.get('name')}
      Телефон: ${formData.get('phone')}
      Email: ${formData.get('email')}
      Компания: ${formData.get('company')}
    `;
    sendMessageToTelegram(message);
    setIsFormSubmitted(true);
    e.target.reset();
    setTimeout(() => {
      setIsFormSubmitted(false);
      setIsModalOpen(false);
    }, 5000);
  };

  const toggleFAQ = (id) => {
    setOpenFAQ(openFAQ === id ? null : id);
  };

  const handleButtonClick = (e, text) => {
    e.preventDefault();
    setModalText(text);
    sendProductLink();
    setIsModalOpen(true);
  };

  const sendProductLink = () => {
    const TOKEN = "7345268657:AAHtV9WfS4I-xQwhBgTqa5fm0D2Zoy8lrbo";
    const CHAT_ID = "-4213649559";
    const url = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
    const data = {
      chat_id: CHAT_ID,
      text: `[INFO] productLink = ${productLink}`,
    };
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const closeFormModal = () => {
    setIsModalOpen(false);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: '#7b3fe4', borderRadius: '50%' }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: '#7b3fe4', borderRadius: '50%' }}
        onClick={onClick}
      />
    );
  }

  return (
    <div className="main-page">
      <header className="header">
        <div className="logo">ReviewMeter</div>
        <nav className="nav">
          <div className="nav-item">Продукт</div>
          <div className="nav-item">Цены</div>
        </nav>
        <div className="actions">
          <button className="login-button" onClick={(e) => handleButtonClick(e, 'Для начала работы заполните анкету')}>Попробовать бесплатно</button>
        </div>
      </header>
      <main className="main-content">
        <section className="hero-section">
          <h1>Всё о ваших отзывах и клиентах в одном месте</h1>
          <p>Получите глубокую аналитику по отзывам, следите за трендами и повышайте удовлетворенность клиентов</p>
          <form onSubmit={(e) => handleButtonClick(e, 'Для начала работы заполните анкету')} className="search-container">
            <input
              type="text"
              placeholder="Введите ссылку на товар"
              value={productLink}
              onChange={(e) => setProductLink(e.target.value)}
            />
            <button type="submit" className="discover-button">Найти инсайты</button>
          </form>
        </section>

        <section className="partners-section">
          <div className="logos">
            
            <img src="ozon.png" alt="Partner Logo 2" className="logo_comp" />
            <img src="sber.png" alt="Partner Logo 3" className="logo_comp" />
            <img src="wb.png" alt="Partner Logo 1" className="logo_comp" />
            <img src="ya.png" alt="Partner Logo 4" className="logo_comp" />
            <img src="ali.png" alt="Partner Logo 5" className="logo_comp" />
          </div>
        </section>

        <section className="features-section">
          <section className="info-block">
            <h2>
              Почему выбирают <span className="highlight">ReviewMeter?</span> 
            </h2>
          </section>

          <div className="features">
            <Feature
              icon={<AiFillDatabase className="icon" />}
              title="Глубокий анализ отзывов"
              description="Получите детальный анализ отзывов по качеству, цене, упаковке и другим аспектам, чтобы всегда быть на шаг впереди конкурентов"
            />
            <Feature
              icon={<FaSearch className="icon" />}
              title="Сравнение с конкурентами"
              description="Сравните отзывы ваших товаров с конкурентами и выявите, в чем вы лучше или где можно улучшить продукт"
            />
            <Feature
              icon={<AiFillPieChart className="icon" />}
              title="Автоматический расчет NPS"
              description="Автоматически получайте расчеты индекса лояльности клиентов (NPS) на основе отзывов, чтобы быстро реагировать на проблемы"
            />
            <Feature
              icon={<AiFillBell className="icon" />}
              title="Мониторинг и уведомления в Telegram"
              description="Получайте мгновенные уведомления о новых трендах и негативе в отзывах прямо в Telegram, чтобы быть всегда в курсе"
            />
          </div>
        </section>

        <section className="product-interface-section">
          <Slider {...sliderSettings}>
            <ProductInterface image="dashboard.png" description="Получите детальный анализ отзывов по качеству, цене, упаковке и другим аспектам, чтобы всегда быть на шаг впереди конкурентов" />
            <ProductInterface image="chatai.png" description="Задайте ИИ любые вопросы о своих товарах" />
            <ProductInterface image="summary.png" description="Получайте регулярные отчеты и уведомления о новых трендах и ключевых словах, используя суммаризацию отзывов и сегментацию клиентов. " />
          </Slider>
        </section>

        <section className="info-block">
          <h2>
            Как <span className="highlight">ReviewMeter</span> работает?
          </h2>
        </section>

        <section className="how-it-works-section">
          <div className="steps">
            <Step
              title="Собирайте отзывы со всех маркетплейсов"
              description="ReviewMeter автоматически собирает отзывы о ваших товарах со всех популярных маркетплейсов, таких как Wildberries, Ozon, Яндекс.Маркет, AliExpress, СберМегаМаркет и другие"
            />
            <Step
              title="ИИ-аналитика отзывов"
              description="Раскройте секреты ваших покупателей с помощью ИИ-анализа отзывов. Получите исчерпывающую информацию о том, что говорят ваши клиенты о цене, дизайне, упаковке и других аспектах. Отслеживайте сентимент и получайте самую важную информацию из отзывов. Автоматически отслеживайте тренды и конкурентов."
            />
            <Step
              title="Получение отчетов и уведомлений"
              description="Получайте регулярные отчеты и уведомления о новых трендах и ключевых словах, используя суммаризацию отзывов и сегментацию клиентов. Эти данные помогут вам адаптировать маркетинговые стратегии и улучшить обслуживание клиентов, предоставляя персонализированные рекомендации и своевременную информацию о предпочтениях вашей аудитории."
            />
          </div>
        </section>

        <section className="info-block">
          <h2>
            Часто <span className="highlight">задаваемые</span> вопросы
          </h2>
        </section>
        <section className="faq-section">
          <FAQ
            id={1}
            question="Как ReviewMeter поможет увеличить продажи и лояльность клиентов?"
            answer="Наш сервис предоставляет глубокий анализ отзывов и конкурентного окружения, что позволяет выявить ключевые области для улучшения вашего товара. Своевременные уведомления о негативных отзывах позволяют быстро реагировать на проблемы, повышая удовлетворенность клиентов. Аналитика трендов и предпочтений покупателей помогает адаптировать маркетинговые стратегии и развивать продукты в соответствии с ожиданиями аудитории, что в конечном итоге способствует увеличению продаж и доходов."
            openFAQ={openFAQ}
            toggleFAQ={toggleFAQ}
          />
          <FAQ
            id={2}
            question="Как ReviewMeter помогает анализировать конкурентов?"
            answer="Уведомления о новых отзывах поступают в реальном времени. Вы получите мгновенные оповещения в Telegram, как только будет опубликован новый отзыв о вашем товаре."
            openFAQ={openFAQ}
            toggleFAQ={toggleFAQ}
          />
          <FAQ
            id={3}
            question="Как ReviewMeter сервис помогает улучшить мою репутацию на маркетплейсах?"
            answer="Наш сервис сравнивает отзывы о ваших товарах с отзывами на товары конкурентов, выявляя сильные и слабые стороны каждой продукции. Вы получаете доступ к детальному анализу конкурентных товаров, что включает оценку качества, цены, упаковки и других аспектов. Эта информация позволяет вам определить, в чем ваши товары превосходят конкурентов, а также где есть возможности для улучшения. Таким образом, вы сможете принимать обоснованные решения для повышения конкурентоспособности и улучшения своих продуктов."
            openFAQ={openFAQ}
            toggleFAQ={toggleFAQ}
          />
          <FAQ
            id={4}
            question="Какие данные я получу в аналитических отчетах?"
            answer="В аналитических отчетах вы получите подробный анализ отзывов по качеству, цене, упаковке и другим аспектам. Также в отчетах будут представлены сравнительные данные с конкурентами, расчет NPS и ключевые тренды в отзывах."
            openFAQ={openFAQ}
            toggleFAQ={toggleFAQ}
          />
        </section>

        <section className="contact-block">
          <div className="contact-container">
            <div className="contact-image">
              <img src="contact.png" alt="Contact Us" />
            </div>
            <div className="contact-form-container">
              <h2>Получите ценные инсайты из отзывов уже сегодня</h2>
              <p>Заполните форму и получите бесплатный аудит отзывов</p>
              <form className="contact-form" onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="Ваше имя" required />
                <input type="tel" name="phone" placeholder="+7 (999) 999-99-99" required />
                <input type="email" name="email" placeholder="Email" required />
                <input type="text" name="company" placeholder="Название вашего магазина или бренда (необязательно)" />
                <div className="consent">
                  <input type="checkbox" required />
                  <span>
                    Нажимая кнопку, вы принимаете пользовательское соглашение и даете согласие на обработку персональной информации на условиях <a href="#">политики конфиденциальности</a>
                  </span>
                </div>
                <button type="submit" className="audit-button">Попробовать бесплатно</button>
              </form>
            </div>
          </div>
        </section>

        {isModalOpen && (
          <Modal isFormSubmitted={isFormSubmitted} closeFormModal={closeFormModal} modalText={modalText} handleSubmit={handleSubmit} />
        )}
      </main>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-nav">
            <div className="footer-nav-item">О нас</div>
            <div className="footer-nav-item">Политика конфиденциальности</div>
            <div className="footer-nav-item">Условия использования</div>
          </div>
          <div className="footer-info">
            <p>© 2024 Reviewmeter. Все права защищены.</p>
            <div className="social-links">
              {/* Ссылки на социальные сети */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

const Feature = ({ icon, title, description }) => (
  <div className="feature">
    {icon}
    <div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
);

const ProductInterface = ({ image, description }) => (
  <div className="product-interface-container">
    <img src={image} alt="Product Interface" className="product-interface-image" />
    <p>{description}</p>
  </div>
);

const Step = ({ title, description }) => (
  <div className="step">
    <div className="step-content">
      <div className="step-text">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  </div>
);

const FAQ = ({ id, question, answer, openFAQ, toggleFAQ }) => (
  <div className={`faq-item ${openFAQ === id ? 'open' : ''}`}>
    <div className="faq-question" onClick={() => toggleFAQ(id)}>
      {question}
      <span className="faq-toggle">{openFAQ === id ? '−' : '+'}</span>
    </div>
    {openFAQ === id && <div className="faq-answer">{answer}</div>}
  </div>
);

const Modal = ({ isFormSubmitted, closeFormModal, modalText, handleSubmit }) => (
  <div className="modal">
    <div className="modal-content">
      <span className="close" onClick={closeFormModal}>&times;</span>
      {isFormSubmitted ? (
        <p>Спасибо, что заполнили!</p>
      ) : (
        <div className="contact-form-container">
          <h2>{modalText}</h2>
          <p>Мы предоставляем доступ к сервису после ручной модерации.</p>
          <p>Пожалуйста, заполните форму и попробуйте наш сервис первыми!</p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Ваше имя" required />
            <input type="tel" name="phone" placeholder="+7 (999) 999-99-99" required />
            <input type="email" name="email" placeholder="Email" required />
            <input type="text" name="company" placeholder="Название вашего магазина или бренда (необязательно)" />
            <div className="consent">
              <input type="checkbox" required />
              <span>
                Нажимая кнопку, вы принимаете пользовательское соглашение и даете согласие на обработку персональной информации на условиях <a href="#">политики конфиденциальности</a>
              </span>
            </div>
            <button type="submit" className="audit-button">Попробовать бесплатно</button>
          </form>
        </div>
      )}
    </div>
  </div>
);

const sendMessageToTelegram = (message) => {
  const TOKEN = "7345268657:AAHtV9WfS4I-xQwhBgTqa5fm0D2Zoy8lrbo";
  const CHAT_ID = "-4213649559";
  const url = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
  const data = {
    chat_id: CHAT_ID,
    text: `[INFO] ${message}`,
  };

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export default MainPage;
