import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { LineChart, Line, PieChart, Pie, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import './DashboardPage.css';

const DashboardPage = () => {
  const data = [
    { name: 'Январь', Положительные: 40, Отрицательные: 24 },
    { name: 'Февраль', Положительные: 30, Отрицательные: 13 },
    { name: 'Март', Положительные: 20, Отрицательные: 98 },
    { name: 'Апрель', Положительные: 27, Отрицательные: 39 },
    { name: 'Май', Положительные: 18, Отрицательные: 48 },
    { name: 'Июнь', Положительные: 23, Отрицательные: 38 },
    { name: 'Июль', Положительные: 34, Отрицательные: 43 }
  ];

  const pieData = [
    { name: 'Положительные', value: 400 },
    { name: 'Отрицательные', value: 300 }
  ];

  const barData = [
    { name: 'Качество', Положительные: 80, Отрицательные: 20 },
    { name: 'Удобство', Положительные: 65, Отрицательные: 35 },
    { name: 'Дизайн', Положительные: 78, Отрицательные: 22 },
    { name: 'Стоимость', Положительные: 85, Отрицательные: 15 }
  ];

  return (
    <div className="dashboard-page">
      <header className="dashboard-header">
        <div className="logo">МойСервис</div>
        <nav className="dashboard-nav">
          <div className="nav-item">Обзор</div>
          <div className="nav-item">Открытия</div>
          <div className="nav-item">Лента</div>
          <div className="nav-item">Аномалии</div>
          <div className="nav-item">Ошибки</div>
          <div className="nav-item">Панели</div>
        </nav>
        <div className="search-bar">
          <FaSearch />
          <input type="text" placeholder="Ищите что угодно" />
        </div>
      </header>
      <main className="dashboard-main">
        <section className="hero-section">
          <p>Проверяйте гипотезы с помощью нашего мощного поиска</p>
          <div className="search-container">
            <FaSearch />
            <input type="text" placeholder="Ищите что угодно о вашем продукте" />
            <button className="discover-button">Открыть инсайты</button>
          </div>
        </section>
        <section className="overview-section">
          <h2>Обзор за последние 30 дней</h2>
          <div className="insights-summary">
            <div className="insight-card">
              <p>Качество товара</p>
              <h3>282</h3>
            </div>
            <div className="insight-card">
              <p>Соответствие описанию</p>
              <h3>199</h3>
            </div>
            <div className="insight-card">
              <p>Удобство использования</p>
              <h3>27</h3>
            </div>
            <div className="insight-card">
              <p>Упаковка и доставка</p>
              <h3>231</h3>
            </div>
            <div className="insight-card">
              <p>Стоимость и ценность</p>
              <h3>15</h3>
            </div>
          </div>
        </section>
        <section className="analytics-section">
          <h2>Аналитика</h2>
          <div className="charts-grid single-row">
            <div className="chart-container">
              <h3>Анализ отзывов по месяцам</h3>
              <LineChart width={400} height={300} data={data}>
                <Line type="monotone" dataKey="Положительные" stroke="#8884d8" />
                <Line type="monotone" dataKey="Отрицательные" stroke="#82ca9d" />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
              </LineChart>
            </div>
            <div className="chart-container">
              <h3>Распределение отзывов</h3>
              <PieChart width={400} height={300}>
                <Pie dataKey="value" isAnimationActive={false} data={pieData} cx={200} cy={150} outerRadius={80} fill="#8884d8" label />
                <Tooltip />
              </PieChart>
            </div>
            <div className="chart-container">
              <h3>Рейтинги по аспектам</h3>
              <BarChart width={400} height={300} data={barData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Положительные" fill="#82ca9d" />
                <Bar dataKey="Отрицательные" fill="#8884d8" />
              </BarChart>
            </div>
          </div>
        </section>
        <section className="detailed-insights-section">
          <h2>Топ 10 инсайтов</h2>
          <div className="insights-grid">
            <div className="insight-box">
              <h3>Что пишут про качество товара</h3>
              <ul>
                <li>"Ткань очень мягкая и приятная на ощупь."</li>
                <li>"После нескольких стирок футболка сохраняет свою форму и цвет."</li>
                <li>"Качество швов на высоком уровне, ничего не торчит."</li>
              </ul>
              <a href="#">Посмотреть все</a>
            </div>
            <div className="insight-box">
              <h3>Что пишут про удобство использования</h3>
              <ul>
                <li>"Футболка отлично сидит и не стесняет движений."</li>
                <li>"Подходит для занятий спортом, так как хорошо впитывает влагу."</li>
                <li>"Можно носить как в повседневной жизни, так и на прогулках."</li>
              </ul>
              <a href="#">Посмотреть все</a>
            </div>
            <div className="insight-box">
              <h3>Что пишут про дизайн</h3>
              <ul>
                <li>"Современный и стильный дизайн, подходит под любой образ."</li>
                <li>"Приятные и насыщенные цвета, которые не выцветают."</li>
                <li>"Простота и элегантность в одном флаконе."</li>
              </ul>
              <a href="#">Посмотреть все</a>
            </div>
            <div className="insight-box">
              <h3>Что пишут про стоимость</h3>
              <ul>
                <li>"Хорошее соотношение цены и качества."</li>
                <li>"Доступная цена за такой высокий уровень качества."</li>
                <li>"Стоит своих денег, особенно учитывая долговечность."</li>
              </ul>
              <a href="#">Посмотреть все</a>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default DashboardPage;
