import React, { useState } from 'react';
import { FaSearch, FaMagic } from 'react-icons/fa';
import './InsightsPage.css';

const InsightsPage = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, user: true }]);
      setInput('');
      // Simulate AI response
      setTimeout(() => {
        setMessages(prevMessages => [
          ...prevMessages,
          {
            text: `Современный и стильный дизайн: Клиенты отмечают, что дизайн футболки современный и стильный, что позволяет носить её в различных ситуациях, будь то повседневная жизнь или особый случай.
  Подходит под любой образ: Футболка хорошо сочетается с различными стилями одежды, что делает её универсальным элементом гардероба.
  Приятные и насыщенные цвета: Отзывы подчеркивают, что цвета футболки приятные и насыщенные, и они не выцветают со временем, что сохраняет её привлекательный внешний вид.`,
            user: false
          }
        ]);
      }, 1000);
    }
  };

  return (
    <div className="insights-page">
      <header className="insights-header">
        <div className="logo">МойСервис</div>
        <nav className="insights-nav">
          <div className="nav-item">Обзор</div>
          <div className="nav-item">Открытия</div>
          <div className="nav-item">Лента</div>
          <div className="nav-item">Аномалии</div>
          <div className="nav-item">Ошибки</div>
          <div className="nav-item">Панели</div>
        </nav>
        <div className="search-bar">
          <FaSearch />
          <input type="text" placeholder="Ищите что угодно" />
        </div>
      </header>

      <section className="hero-section">
        <p>Откройте для себя глубокие инсайты о вашем продукте</p>
        <div className="search-container">
          <FaSearch />
          <input type="text" placeholder="Ищите что угодно о вашем продукте" />
          <button className="discover-button">Открыть инсайты</button>
        </div>
      </section>

      <main className="insights-main">
        <section className="insights-content">
          <article className="insights-block">
            <h3>Резюме</h3>
            <p>
              Клиенты в целом довольны качеством товара, подчеркивая мягкость и приятность ткани, а также сохранение формы и цвета после стирок. Также положительно оценивается удобство использования футболки, особенно во время занятий спортом и в повседневной жизни. Дизайн футболки также получил положительные отзывы за свою современность и стиль, а также за насыщенные цвета. Стоимость футболки, по мнению клиентов, соответствует качеству, предлагая хорошее соотношение цены и качества. Однако, как и любой продукт, существуют области, требующие улучшения.
            </p>
          </article>
          <div className="insights-row">
            <article className="insights-block negative">
              <h3>Негатив</h3>
              <ul>
                <li>Дорого</li>
                <li>После нескольких стирок футболка начинает терять форму</li>
                <li>Некоторые швы начинают распускаться после нескольких носок</li>
                <li>Футболка не подходит для интенсивных занятий спортом</li>
                <li>Цвета со временем начинают тускнеть</li>
              </ul>
            </article>
            <article className="insights-block positive">
              <h3>Позитив</h3>
              <ul>
                <li>Футболка отлично сидит и не стесняет движений</li>
                <li>Современный и стильный дизайн, подходит под любой образ</li>
                <li>Ткань очень мягкая и приятная на ощупь</li>
                <li>Хорошее соотношение цены и качества</li>
              </ul>
            </article>
          </div>
        </section>
        <div className="chat-box">
          <div className="chat-header">
            <FaMagic className="magic-icon" />
            Чат с ИИ
          </div>
          <div className="chat-content">
            {messages.map((msg, index) => (
              <div key={index} className={`chat-message ${msg.user ? 'user' : 'ai'}`}>
                {msg.text}
              </div>
            ))}
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Введите ваше сообщение..."
            />
            <button onClick={handleSend}>Отправить</button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default InsightsPage;
